// eslint-disable-next-line @typescript-eslint/no-namespace
import {
  BandmanagerRestApiBand,
  BandmanagerRestApiBandStatsDto,
  BandmanagerRestApiBankaccount, BandmanagerRestApiPlayer,
  BandmanagerRestApiPlayerWithRanksDto,
  BandmanagerRestApiUniform
} from "@digitale-menschen/bandmanager-rest-api";
import {RehearsalInfosInterface} from "../../app/models/rehearsal-infos.interface";

export namespace BandStateActions {

  export class SetBand {
    static readonly type = '[Band] SetBand';

    constructor(public band: BandmanagerRestApiBand) {
    }
  }

  export class SetBankAccount {
    static readonly type = '[Band] SetBankAccount';

    constructor(public bankAccount: BandmanagerRestApiBankaccount) {
    }
  }

  export class SetStats {
    static readonly type = '[Band] SetStats';

    constructor(public stats: BandmanagerRestApiBandStatsDto) {
    }
  }

  export class SetUniform {
    static readonly type = '[Band] SetUniform';

    constructor(public uniform: BandmanagerRestApiUniform) {
    }
  }

  export class SetRehearsal {
    static readonly type = '[Band] SetRehearsal';

    constructor(public rehearsal: RehearsalInfosInterface) {
    }
  }

  export class SetPlayers {
    static readonly type = '[Band] SetPlayers';

    constructor(public players: BandmanagerRestApiPlayerWithRanksDto[]) {
    }
  }

  export class UpdatePlayer {
    static readonly type = '[Band] UpdatePlayer';

    constructor(public player: BandmanagerRestApiPlayerWithRanksDto) {
    }
  }
}
